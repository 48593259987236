<template>
  <div class="canxuanCommodityDistribution">
      <div class="search-features">
        <van-search v-model="value" placeholder="搜索全部商品!"  @search="onSearch" left-icon right-icon="search"/>
      </div>
      <div class="search-features-2">
        
      </div>

      <van-grid square clickable>
        <van-grid-item :class="{'host-van-grid':rankType!=1}" icon="clock-o" text="实时爆品" :icon-color="rankType==1?'':'#f1e6e6'" v-on:click="vanFridItem(1)"/>
        <van-grid-item :class="{'host-van-grid':rankType!=2}" icon="underway-o" text="昨日新兴" :icon-color="rankType==2?'':'#f1e6e6'" v-on:click="vanFridItem(2)"/>
        <van-grid-item :class="{'host-van-grid':rankType!=3}" icon="calendar-o" text="七日热销" :icon-color="rankType==3?'':'#f1e6e6'" v-on:click="vanFridItem(3)"/>
        <van-grid-item :class="{'host-van-grid':rankType!=4}" icon="hot-o" text="持续好货" :icon-color="rankType==4?'':'#f1e6e6'" v-on:click="vanFridItem(4)"/>
      </van-grid>

      <van-tabs  @click-tab="onClickTab">
        <van-tab title="全部">
        </van-tab>
        <van-tab v-for="obj in categoryList" :title="obj.category" :key="obj.id">
        </van-tab>
      </van-tabs>

        <van-list
          v-model:loading="loading"
          :finished="finished"
          :finished-text="finishedText"
          @load="onLoad"
          :offset="300"
        >
          <div class="div-card" v-for="item,key in productList" :key="key">
              <van-card 
                        :price="item.activity_price_trans.toFixed(2)" 
                        :desc="item.cmm_cname" 
                        :title="item.title" 
                        :thumb-link="item.detail_url" 
                        :thumb="item.cover" 
                        :origin-price="item.origin_price.toFixed(2)"
                        :centered="true"
                        v-on:click="detail(item.product_id)"
                        >
                <template #num>
                  销量{{(item.sales/10000).toFixed(2)}}W
                </template>
                <template #tags>
                  <van-tag plain type="primary">佣金率{{item.activity_cos_ratio_trans}}%</van-tag>
                    &nbsp;
                    <van-tag plain type="primary" v-if="item.category_name!=category">{{item.category_name}}</van-tag>
                    &nbsp;
                    <van-tag plain type="primary" v-if="rankType==1 && item.hour_2_volume!=null">近两个小时销量{{item.hour_2_volume>10000?(item.hour_2_volume/10000).toFixed(2)+'W':item.hour_2_volume}}</van-tag>
                    <van-tag plain type="primary" v-if="rankType==2 && item.day_volume!=null">昨天销量{{item.day_volume>10000?(item.day_volume/10000).toFixed(2)+'W':item.day_volume}}</van-tag>
                    <van-tag plain type="primary" v-if="rankType==3 && item.day7_volume!=null">7内天销量{{item.day7_volume>10000?(item.day7_volume/10000).toFixed(2)+'W':item.day7_volume}}</van-tag>
                    <van-tag plain type="primary" v-if="rankType==4 && item.day30_volume!=null">30内天销量{{item.day30_volume>10000?(item.day30_volume/10000).toFixed(2)+'W':item.day30_volume}}</van-tag>
                </template>
              </van-card>
          </div>
    </van-list>
  </div>
</template>
<script>

export default {
  name: 'CanxuanCommodityDistribution',
  data(){
    return{
      account:'1',
      password:'2',
      productList:[],
      loading:false,
      finished:false,
      page:1,
      value:'',
      categoryList:[],
      rankType:1,
      category:'',
      finishedText:'热门爆品榜单没有更多了',
    }
  },
  created(){
    this.initCategory();
  },
  methods:{
    detail(goodsId){
      this.$router.push({
        name:'CmmJxGoodsDetails',
        query: {goodsId:goodsId}
      })
    },
    onSearch(){
      if(this.value=='' || this.value ==null || this.value==undefined)return;
      this.$router.push({
        name:'canxuanCommodityAll',
        query: {titleName:this.value,category:this.category}
      })
    },
    onClickTab({ title }){
      if(title == '全部'){
        title = '';
      }
      if(this.category != title){
        this.category = title;
        this.productList = [];
        this.page = 1;
        this.finished = false;
        this.onLoad();
      }
    },
    vanFridItem(value){
      if(this.rankType != value){
        this.productList = [];
        this.page = 1;
        this.rankType = value;
        this.finished = false;
        this.onLoad();
      }
    },
    initCategory(){
      // 异步更新数据
      this.axios.post('/app/canmama/categoryQry', {
            }).then((response) => {
              this.categoryList = response;
            }).catch(function (error) {
                console.log(error);
            });
    },
    onLoad(){
      if(this.page > 20){
        return;
      }
      
      //设置为加载中
      this.loading = true;
      // 异步更新数据
      this.axios.post('/app/canmama/hotGoodsRank', {
                  page:this.page,
                  rankType:this.rankType,
                  category:this.category
            }).then((response) => {
              console.log(response);
              this.productList = [...this.productList,...response.list];
              // 数据全部加载完成
              if (response.page_info.page == response.page_info.total_page || response.list.length<10) {
                this.finished = true;
              }
            }).catch(function (error) {
                console.log(error);
            });
            // 加载状态结束
            this.loading = false;
            this.page++;
            if(this.productList.length == 0){
              this.finishedText = this.category + '热门爆品榜单没有更多了!';
            }
    }
  }
}
</script>
<style>
  .search-features {
    position: fixed !important;
    z-index: 99 !important;
    width:100% !important;
  }
  .search-features-2 {
    height: 45px;
  }
  .div-card {
    padding: 1px 0;
  }
  .canxuanCommodityDistribution {
    /* background-color: #f9f9f9; */
    --van-card-background-color	:#fff;
  }
  .host-van-grid {
    --van-grid-item-text-color:#f1e6e6;
  }
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
  }
</style>
